
import {
  reactive,
  toRefs,
  computed,
  watchEffect,
  inject,
} from "vue"
import { useStore } from "vuex"

import { getGiftsApi } from "@/api/home"
import { 
  defaultError,
} from '@/utils/common.js'

import ScrollLoadWrap from "@/components/ScrollLoadWrap"
import ProductModal from "@/components/ProductModal/ProductModal.vue"

export default {
  name: 'Mall',
  components: {
    ScrollLoadWrap,
    ProductModal,
  },
  setup() {
    const data = reactive({
      giftList: [],
      isShowProductModal: false,
      currentGiftId: 0,
    })

    const store = useStore()
    const toast = inject('toast')
    const loading = inject('loading')

    const isFullScreen = computed(() => store.state.isFullScreen)

    const scrollLoad = () => {
      console.log('scroll bottom')
    }

    // 获取礼品列表
    watchEffect( () => {
      if(!store.state.code) return
      getGifts()
    })
    async function getGifts() {
      loading.show()
      const res = await getGiftsApi({
        activityCode: store.state.code,
      })
      loading.hide()

      if(res.code === 0) {
        data.giftList = res.data.giftItemViewModels
      }
      else {
        toast({
          message: res.message || defaultError,
        })
      }
    }

    const showProductModal = (index) => {
      data.currentGiftId = data.giftList[index].id
      data.isShowProductModal = true
    }

    return {
      ...toRefs(data),
      isFullScreen,
      scrollLoad,
      showProductModal,
    }
  },
} 