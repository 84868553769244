<template>
  <div class="wrap">
    <router-view v-slot="{ Component }">
      <keep-alive :include="catchList">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script>
import { 
  computed, 
  onMounted,
  inject, 
} from "vue"
import { useStore } from "vuex"
import { SET_TOKEN, SET_IS_FULL_SCREEN, SET_CODE, SET_MY_POINTS } from '@/store/mutation-types.js'
import { 
  getQueryParameter, 
  judgeIsFullScreen,
  redirectRegister,
  defaultError,
} from '@/utils/common.js'
import { getActivityCodeApi, getUserPoint } from "@/api"

export default {
  name: 'App',
  setup() {
    const store = useStore()
    const toast = inject('toast')
    const loading = inject('loading')

    // 缓存的页面
    const catchList = computed(() => store.state.catchList)

    // 记录用户token
    const token = getQueryParameter('token') || store.state.token
    if(token) {
      store.commit(SET_TOKEN, token)
    }
    else {
      redirectRegister()
    }

    // 全面屏
    store.commit(SET_IS_FULL_SCREEN, judgeIsFullScreen())

    onMounted(async () => {
      // 获取最新活动Code
      loading.show()
      const res = await getActivityCodeApi()
      const userPointResult = await getUserPoint()
      loading.hide()
      
      if(res.code === 0) {
        store.commit(SET_CODE, res.data)
      } else {
        toast({
          message: res.message || defaultError,
        })
      }

      if (userPointResult.data >= 0) {
        store.commit(SET_MY_POINTS, userPointResult.data)
      }

    })

    return {
      catchList,
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/css/main.scss";
</style>