import axios from 'axios' 
import store from "@/store"

// 创建axios实例
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {
    "Content-Type": "application/json"
  },
})

request.interceptors.request.use(
  (config) => {
    const token=store.state.token
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`
    }
    
    return config
  },
  (error) => {
    console.log(error) // for debug
  }
)

// 添加响应拦截器
request.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    return response.data
  }, 
  (error) => {
    // 对响应错误做点什么
    return error.response
  }
)

export {
  request
}
