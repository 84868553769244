<template>
  <teleport v-if="isVisible" to="body">
    <div class="errorPopup">
      <div class="errorPopup-content">
        <img src="@/assets/images/icon/fail.png" class="errorPopup-img">
        <div class="errorPopup-txt">{{txt}}</div>
      </div>
    </div>
  </teleport>
</template>

<script>
import {
  watch,
} from 'vue'

export default {
  name: 'ErrorPopup',
  emits: ['update:isVisible'],
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    txt: {
      type: String,
      default: ''
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.isVisible, 
      (val) => {
        if(val) {
          setTimeout(() => {
            emit('update:isVisible', false)
          }, 2000)
        }
      }
    )
  },
} 
</script>

<style scoped lang="scss">
.errorPopup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 40;
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  &-content {
    width: 454px;
    padding: 46px 0 42px;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 15px;
  }
  &-img {
    display: block;
    width: 56px;
    margin: 0 auto 20px;
  }
  &-txt {
    padding: 0 30px;
    font-size: 24px;
    line-height: 34px;
    color: #fff;
    text-align: center;
    word-break: break-all;
  }
}
</style>