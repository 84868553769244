import { useRouter, useRoute } from 'vue-router'
import { useStore } from "vuex"

import {
  reactive,
  toRefs,
  computed,
  watch,
  watchEffect,
  inject,
} from "vue"

import { getActivityApi } from "@/api"
import { 
  defaultError,
  thousands,
} from '@/utils/common.js'

import dayjs from 'dayjs'

export default {
  name: 'Home',
  components: {
    
  },
  setup() {
    const data = reactive({
      routeName: 'Mall',
      name: '',
      date: '',
    })
    
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const toast = inject('toast')
    const loading = inject('loading')

    // tab相关
    watch(
      () => route.name, 
      (newName) => {
        data.routeName = newName
      },
      { immediate: true }
    )
    const toTabPage = (e) => {
      const name = e.srcElement.dataset.name;
      if (name === data.routeName) return;

      router.push({
        name,
      })
    }

    // 获取活动信息
    const points = computed(() => thousands(store.state.myPoints))
    const isOpen = computed(() => store.state.isOpen)
    watchEffect(async () => {
      if (!store.state.code) return;

      loading.show();
      const res = await getActivityApi({
        activityCode: store.state.code,
      });
      loading.hide();

      if (res.code === 0) {
        data.name = res.data.name
        
        const startTimeStr = dayjs(res.data.startTimeStr).format('YYYY年M月D日')
        const endTimeStr = dayjs(res.data.endTimeStr).format('YYYY年M月D日')
        data.date = `${startTimeStr}-${endTimeStr}`
      }
      else {
        toast({
          message: res.message || defaultError,
        })
      }
    })

    return {
      ...toRefs(data),
      points,
      isOpen,
      toTabPage,
    }
  },
} 