import { request } from '@/utils/request'

export function getCityListApi() {
  return request({
    baseURL: process.env.VUE_APP_HOSPITAL_API,
    method: 'post',
    url: '/business/mini/hospital/getCityList',
  })
}

// province
// city
// hospitalName: 用户输入的keyword
export function getHospitalListApi(data) {
  return request({
    baseURL: process.env.VUE_APP_HOSPITAL_API,
    method: 'post',
    url: '/business/mini/hospital/getHospitalList',
    data,
  })
}

export function getDepartListApi() {
  return request({
    baseURL: process.env.VUE_APP_HOSPITAL_API,
    method: 'post',
    url: '/business/mini/departments/getDepartList',
    data: {
      name: ""
    }
  })
}

// 获取最新活动Code
export function getActivityCodeApi() {
  return request({
    method: 'post',
    url: '/Activity/GetLatestActivityCode',
  })
}

// 获取活动信息
// activityCode-活动code
export function getActivityApi(data) {
  return request({
    method: 'post',
    url: '/Activity/GetActivityByCode',
    data,
  })
}

// 获取用户积分
export function getUserPoint() {
  return request({
    method: 'post',
    url: '/User/GetUserPoint'
  })
}
