<template>
  <div class="scrollLoadWrap" ref="scrollLoadWrap">
    <div ref="scrollLoadContent">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { 
  ref,
  onMounted,
  onBeforeUnmount,
} from 'vue'
import debounce from "lodash/debounce"


export default {
  name: 'ScrollLoadWrap',
  emits: ['scrollLoad'],
  setup(props, { emit }) {
    // 创建一个DOM引用，名称必须与元素的ref属性名相同
    const scrollLoadWrap = ref(null)
    const scrollLoadContent = ref(null)
    // 滚动加载节流
    const scrollFunc = debounce(scrollLoad, 200)
    // 滚动到底部加载
    function scrollLoad() {
      let scrollTop = scrollLoadWrap.value.scrollTop
      let wrapH = scrollLoadWrap.value.clientHeight // 显示区域高度
      let boxH = scrollLoadContent.value.offsetHeight // 元素总的高度
      
      // -100是因为安卓兼容性问题
      if (scrollTop + wrapH >= boxH - 100) {
        emit('scrollLoad')
      }
    }

    onMounted(() => {
      scrollLoadWrap.value.addEventListener("scroll", handleScroll)
    })
    onBeforeUnmount(() => {
      scrollLoadWrap.value.removeEventListener("scroll", handleScroll)
    })
    function handleScroll() {
      scrollFunc()
    }

    return {
      scrollLoadWrap,
      scrollLoadContent,
    }
  },
} 
</script>

<style scoped lang="scss">
.scrollLoadWrap {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
</style>