import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import toast from '@/components/toast'
import loading from '@/components/loading'


createApp(App)
  .use(store)
  .use(router)
  .use(toast)
  .use(loading)
  .mount("#app")
