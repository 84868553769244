<template>
  <teleport v-if="isVisible" to="body">
    <div class="productModal">
      <div class="productModal-content">
        <div class="productModal-info">
          <div class="productModal-imgBox">
            <img :src="gift.image" class="productModal-img" alt="" @click="previewImage(gift.image)" />
          </div>

          <div class="productModal-caption">
            <div class="productModal-main">
              <div class="productModal-name">{{gift.name}}</div>
              <div class="productModal-stock">
                库存：<span class="productModal-quantity">{{stock}}</span> 件
              </div>
            </div>

            <div class="productModal-bottom">
              <div class="productModal-points">{{price}}</div>
              <div class="productModal-txt">积分</div>
            </div>

            <div @click="hideProductModal" class="productModal-closeBox">
              <img src="@/assets/images/icon/close-black.png" class="productModal-close">
            </div>
          </div>
        </div>

        <div class="productModal-optionsWrap">
          <div class="productModal-options">
            <template
              v-for="(item, index) in specList"
              :key="index">
              <div class="option-name">{{item.attributeKey}}</div>
              <div class="option-box">
                <div
                  v-for="(optionItem, optionIndex) in item.attributeItemViewModels"
                  :key="optionItem.id"
                  class="option-item"
                  :class="{active: optionItem.active, disabled: optionItem.disabled}"
                  :data-index="index"
                  :data-optionindex="optionIndex"
                  @click="onOption"
                >{{optionItem.attributeValue}}</div>
              </div>
            </template>

            <div class="option-name">数量</div>
            <div class="option-countBox">
              <div
                class="option-count"
                :class="{disabled: num <= 0}"
                @click="onMinus">-</div>
              <div class="option-num">
                <input v-model="num" type="tel" />
              </div>
              <div
                class="option-count"
                :class="{disabled: num >= stock}"
                @click="onPlus">+</div>
            </div>
          </div>
        </div>

        <div class="productModal-btnWrap" :class="{isFull: isFullScreen}">
          <div @click="onRedeem" class="productModal-btn">立即兑换</div>
          <div
            v-if="isShowJoinedBtn"
            @click="onJoined"
            class="productModal-btn">加入购物车</div>
        </div>
      </div>
    </div>
  </teleport>

  <JoinedCartSuccess v-model:isVisible="isShowSuccess" />
  <ErrorPopup
    v-model:isVisible="isShowErrorPopup"
    :txt="errorTxt" />
</template>

<script src='./ProductModal.js'></script>

<style scoped lang="scss" src="./ProductModal.scss"></style>
