// 点击结算按钮时的逻辑
import { 
  getAddressApi,
} from "@/api/address"
import { 
  defaultError,
} from '@/utils/common.js'
import store from "@/store"

export function checkPoints(totalPrice, myPoints) {
  if(totalPrice + store.state.expressFee > myPoints) {
    return {
      success: false,
      error: '已超出可用积分，请再次确认',
    }
  }
  else {
    return {
      success: true,
    }
  }
}

export async function settlement({toast, loading, toNext}) {
  loading.show()
  const res = await getAddressApi()
  loading.hide()

  if(res.code === 0) {
    if(res.data.length === 0) {
      // 没有地址，去填写地址页
      toNext({name: 'FillAddress', query: {from: 'settlement'}})
    }
    else {
      toNext({name: 'SubmitOrder'})
    }
  }
  else {
    toast({
      message: res.message || defaultError,
    })
  }
}