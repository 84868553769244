
import { render, createVNode } from "vue"
import LoadingComponent from './Loading.vue'

class Loading {
  constructor() {

  }

  init() {
    let [loadingWrapper, loadingVM] = [null, null]
    loadingWrapper = document.createElement("div")
    loadingVM = createVNode(
      LoadingComponent,
    )
    render(loadingVM, loadingWrapper)
    document.body.appendChild(loadingWrapper)

    return loadingVM.component.proxy
  }
}

Loading.install = (app) => {
  let loading = new Loading().init()
  app.config.globalProperties.$loading = loading
  app.provide("loading", loading)
};

export default Loading

// 使用说明
// 1.在setup中

// setup() {
//   const loading = inject('loading')

//   loading.show()
//   loading.hide()
// },

// 2.在vue2模式中

// this.$loading.show()
// this.$loading.hide()