<template>
  <div class="home">
    <div class="titleWrap">
      <div class="titleWrap-l">
        <div class="title" v-if="name && name.length > 0">{{name}}</div>
        <div class="title" v-else>商城准备中...</div>
        <div class="subTitle">
          开放时间：
          <span v-if="date">{{date}}</span>
          <span v-else>敬请期待</span>
        </div>
      </div>

      <div class="titleWrap-r">
        <div class="pointsBox">
          <div class="points-title">我的积分</div>
          <span>{{ points }}</span>
        </div>
      </div>
    </div>

    <div class="content" v-show="isOpen">
      <div class="tabWrap">
        <div 
          class="tab-cell"
          :class="{active: routeName === 'Mall'}"
          data-name="Mall"
          @click="toTabPage"
        >商城</div>
        <div 
          class="tab-cell"
          :class="{active: routeName === 'ShoppingCart'}"
          data-name="ShoppingCart"
          @click="toTabPage"
        >购物车</div>
        <div 
          class="tab-cell"
          :class="{active: routeName === 'History'}"
          data-name="History"
          @click="toTabPage"
        >兑换历史</div>
      </div>

      <main>
        <router-view></router-view>
      </main>
    </div>
    <div class="content" v-show="!isOpen">
      <div class="waitToOpen">
        <img src="@/assets/images/creditmall_close.png" />
        <p>商城准备中，很快就回来！</p>
        <p>敬请期待~</p>
      </div>
    </div>
  </div>
</template>

<script src='./Home.js'></script>

<style scoped lang="scss" src="./Home.scss"></style>