import {
  reactive,
  toRefs,
  computed,
  watch,
  watchEffect,
  inject,
} from "vue"
import {
  useStore
} from "vuex"
import {
  useRouter
} from 'vue-router'

import {
  getGiftAttributesApi
} from "@/api/home"
import {
  addCartApi,
  updateCartApi
} from "@/api/cart"
import {
  defaultError,
} from '@/utils/common.js'
import {
  checkPoints,
  settlement
} from '@/use/settlement'
import {
  SET_ORDER_GIFTS
} from '@/store/mutation-types.js'
import { ImagePreview } from 'vant'

import JoinedCartSuccess from "@/components/JoinedCartSuccess"
import ErrorPopup from "@/components/ErrorPopup"

export default {
  name: 'ProductModal',
  components: {
    JoinedCartSuccess,
    ErrorPopup,
  },
  emits: ['update:isVisible', 'updateGift'],
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    giftId: {
      type: Number,
      default: 0
    },
    shoppingCartId: {
      type: Number,
      default: 0
    },
    spec1: {
      type: String,
      default: ''
    },
    spec2: {
      type: String,
      default: ''
    },
    defaultNum: {
      type: Number,
      default: 0
    },
    //submitOrder-在提交订单页打开
    //shoppingCart-在购物车页打开
    type: {
      type: String,
      default: ''
    },
  },
  setup(props, {
    emit
  }) {
    const data = reactive({
      gift: {},
      specList: [],
      skuList: [],
      selectSpec: [], // 记录已选的数据 e.g ['红色', '32G']
      stock: 0,
      num: 0,
      isShowSuccess: false,
      isShowErrorPopup: false,
      errorTxt: '',
    })

    const store = useStore()
    const router = useRouter()
    const toast = inject('toast')
    const loading = inject('loading')

    const isFullScreen = computed(() => store.state.isFullScreen)

    watch(
      () => props.isVisible,
      async (newVal) => {
        if (newVal) {
          data.gift = {}
          data.specList = []
          data.skuList = []
          data.selectSpec = []
          data.stock = 0
          data.num = 0

          loading.show()
          await getGiftAttributes()
          loading.hide()
        }
      }
    )
    async function getGiftAttributes() {
      const res = await getGiftAttributesApi({
        giftId: props.giftId,
      })

      if (res.code === 0) {
        data.gift = res.data.giftItemViewModel
        data.skuList = res.data.giftStockItemViewModels.map(x => {
          // 将sku中的所有规格整合在一起，specs: ["红色", "套餐一", "128G"]
          let specs = []
          for (let index in res.data.attributeListViewModels) {
            index = Number(index)
            specs[index] = x[`attributeValue${index+1}`]
          }
          x.specs = specs
          return x
        })

        data.selectSpec = initSelectSpec(
          res.data.attributeListViewModels.length,
          data.skuList
        );
        data.specList = updateAttrStatus(res.data.attributeListViewModels)
        updateSelectedStock()

        // 如果是传进来的选择数量，就显示传入的数量。如果是首页选购，有库存的情况下，默认选择1
        if (props.defaultNum && props.spec1) {
          data.num = props.defaultNum;
        } else if (data.stock > 0) {
          data.num = 1;
        }
      } else {
        toast({
          message: res.message || defaultError,
        })
      }
    }

    function initSelectSpec(attrListLen, skuList) {
      let selectSpec = new Array(attrListLen).fill("");
      if (props.spec1) {
        selectSpec[0] = props.spec1;
      } else if (
        skuList.length > 0 &&
        skuList[0].specs &&
        skuList[0].specs.length > 0
      ) {
        selectSpec[0] = skuList[0].specs[0]
      }
      if (props.spec2) {
        selectSpec[1] = props.spec2;
      } else if (
        skuList.length > 0 &&
        skuList[0].specs &&
        skuList[0].specs.length > 1
      ) {
        selectSpec[1] = skuList[0].specs[1]
      }

      // 检查传入组件的sku是否有库存
      const matchIndex = skuList.findIndex(skuItem => {
        let count = 0
        skuItem.specs.map((specsItem, specsIndex) => {
          if (specsItem === selectSpec[specsIndex]) count++
        })

        return count === skuItem.specs.length
      })

      if (matchIndex > -1) {
        return selectSpec
      } else {
        // 如果传入组件的sku，没有对应的库存，不选中规格
        return new Array(attrListLen).fill('')
      }
    }
    // 选项处于什么状态
    function updateAttrStatus(specList) {
      return specList.map((specItem, specIndex) => {
        specItem.attributeItemViewModels = specItem.attributeItemViewModels.map(attrItem => {
          attrItem.active = attrItem.attributeValue === data.selectSpec[specIndex]
          attrItem.disabled = isDisabled(specIndex, attrItem.attributeValue)

          return attrItem
        })
        return specItem
      })
    }
    // 判断规格是否可以被选择
    function isDisabled(specIndex, attrValue) {
      let copySelectSpec = JSON.parse(JSON.stringify(data.selectSpec))
      // 选中的规格和当前需要被验证的规格组成一个数组
      copySelectSpec[specIndex] = attrValue
      // 核心判断逻辑
      const flag = data.skuList.some(item => {
        let count = 0

        for (let k in copySelectSpec) {
          if (copySelectSpec[k] === "") {
            count++
          } else if (copySelectSpec[k] === item.specs[k]) {
            count++
          }
        }

        return count === data.selectSpec.length
      })

      return !flag
    }

    function updateSelectedStock() {
      const isSelectedAll = data.selectSpec.every(x => x !== '')
      if (isSelectedAll) {
        // 规格都选择了，显示对应的库存
        const selectSku = findSelectSku()
        data.stock = selectSku.stock
      } else {
        // 默认库存
        data.stock = data.gift.stock
      }
    }

    function findSelectSku() {
      return data.skuList.find(skuItem => {
        let count = 0
        skuItem.specs.map((specsItem, specsIndex) => {
          if (specsItem === data.selectSpec[specsIndex]) count++
        })

        return count === skuItem.specs.length
      })
    }
    // 当前sku的积分
    const price = computed(() => {
      const isSelectedAll = data.selectSpec.every(x => x !== '')
      if (isSelectedAll) {
        const selectSku = findSelectSku()
        if (selectSku && selectSku.price) {
          return selectSku.price
        } else {
          return data.gift.price
        }
      } else {
        return data.gift.price
      }
    })

    const onOption = (e) => {
      const dataset = e.srcElement.dataset
      const index = Number(dataset.index)
      const optionindex = Number(dataset.optionindex)
      const currentOption = data.specList[index].attributeItemViewModels[optionindex]
      if (currentOption.disabled) return

      const selectVal = currentOption.attributeValue
      if (data.selectSpec[index] === selectVal) {
        data.selectSpec[index] = ''
      } else {
        data.selectSpec[index] = selectVal
      }

      data.specList = updateAttrStatus(data.specList)
      updateSelectedStock()
    }

    const onMinus = () => {
      data.num--
    }
    const onPlus = () => {
      data.num++
    }
    watchEffect(() => {
      if (data.num > data.stock) {
        data.num = data.stock
      } else if (isNaN(data.num)) {
        data.num = 1
      } else if (data.num < 0) {
        data.num = 1
      } else if (data.num % 1 !== 0) {
        data.num = Math.round(data.num)
      }
    })

    const hideProductModal = () => {
      emit('update:isVisible', false)
    }

    const isShowJoinedBtn = computed(() => {
      return props.type !== 'submitOrder'
    })
    // 点击加入购物车
    const onJoined = () => {
      if (!checkSpecs()) return

      let attrIds = {}
      let attrVal = {}
      const selectSku = findSelectSku()
      data.selectSpec.map((selectItem, selectIndex) => {
        attrIds[`attributeRecord${selectIndex+1}Id`] = selectSku[`attributeRecord${selectIndex+1}_Id`]
        attrVal[`attributeRecord${selectIndex+1}Value`] = selectSku[`attributeValue${selectIndex+1}`]
      })

      if (props.type === 'shoppingCart') {
        updateCart(attrIds, attrVal)
      } else {
        joinedCart(attrIds)
      }
    }
    async function updateCart(attrIds, attrVal) {
      loading.show()
      const res = await updateCartApi({
        shoppingCarId: props.shoppingCartId,
        count: data.num,
        ...attrIds,
      })
      loading.hide()

      if (res.code === 0) {
        emit('update:isVisible', false)
        emit('updateGift', {
          shoppingCartId: props.shoppingCartId,
          count: data.num,
          price: price.value,
          ...attrIds,
          ...attrVal,
        })
      } else {
        toast({
          message: res.message || defaultError,
        })
      }

    }
    async function joinedCart(attrIds) {
      loading.show()
      const res = await addCartApi({
        activityCode: store.state.code,
        giftId: data.gift.id,
        count: data.num,
        ...attrIds,
      })
      loading.hide()

      if (res.code === 0) {
        data.isShowSuccess = true
        emit('update:isVisible', false)
      } else {
        toast({
          message: res.message || defaultError,
        })
      }

    }

    // 检查所有规格是否都选了
    function checkSpecs() {
      const blankIndex = data.selectSpec.findIndex(x => x === '')
      if (blankIndex > -1) {
        toast({
          duration: 1500,
          message: `请选择${data.specList[blankIndex].attributeKey}`,
        })
        return false
      }
      if (data.num <= 0) {
        toast({
          duration: 1500,
          message: `请选择数量`,
        })
        return false
      }

      return true
    }

    // 立即兑换
    const onRedeem = () => {
      if (!checkSpecs()) return

      let attrs = {}
      const selectSku = findSelectSku()
      data.selectSpec.map((selectItem, selectIndex) => {
        attrs[`attributeRecord${selectIndex+1}Id`] = selectSku[`attributeRecord${selectIndex+1}_Id`]
        attrs[`attributeRecord${selectIndex+1}Value`] = selectSku[`attributeValue${selectIndex+1}`]
      })
      const giftParams = {
        count: data.num,
        price: price.value,
        ...attrs,
      }

      if (props.type === 'submitOrder') {
        onUpdateGift(giftParams)
      } else {
        toSubmit(giftParams)
      }
    }
    // 在提交订单页点击 立即兑换，更新对应礼品数据
    function onUpdateGift(giftParams) {
      emit('update:isVisible', false)
      emit('updateGift', giftParams)
    }

    const myPoints = computed(() => store.state.myPoints)
    // 去提交订单页
    function toSubmit(giftParams) {
      const totalPrice = price.value * data.num
      const res = checkPoints(totalPrice, myPoints.value)
      if (!res.success) {
        data.isShowErrorPopup = true
        data.errorTxt = res.error
        return
      }

      const toNext = (routeObj) => {
        const gift = [{
          ...giftParams,
          shoppingCartId: props.shoppingCartId,
          giftId: props.giftId,
          giftName: data.gift.name,
          image: data.gift.image,
        }]

        store.commit(SET_ORDER_GIFTS, gift)
        emit('update:isVisible', false)
        router.push(routeObj)
      }

      settlement({
        toast,
        loading,
        toNext,
      })
    }

    // 预览图片
    function previewImage(image) {
      ImagePreview([image]);
    }

    return {
      ...toRefs(data),
      hideProductModal,
      isFullScreen,
      onOption,
      onMinus,
      onPlus,
      isShowJoinedBtn,
      onRedeem,
      price,
      onJoined,
      previewImage
    }
  },
}
