<template>
  <div v-if="isShow" class="toast">
    <div class="toast-content">
      {{message}}
    </div>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  onMounted,
  onUnmounted,
} from "vue"

export default {
  name: 'Toast',
  props: {
    id: String,
    duration: Number,
    message: String,
    cb: Function,
  },
  setup(props) {
    const state = reactive({
      isShow: false,
      timer: null,
    })

    const startTimer = () => {
      state.timer = setTimeout(() => {
        props.cb()
        state.isShow = false
        document.body.removeChild(document.getElementById(props.id))
      }, props.duration)
    }

    onMounted(() => {
      startTimer()
      state.isShow = true
    })
    onUnmounted(() => {
      clearTimeout(state.timer)
    })

    return {
      ...toRefs(props),
      ...toRefs(state),
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.toast {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .toast-content {
    width: 50%;
    padding: 30px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    font-size: 30px;
    line-height: 1.2;
    color: #fff;
    text-align: center;
    word-break: break-all;
  }
}

</style>
