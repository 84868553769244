import { request } from '@/utils/request'

// 获取礼品列表
// activityCode-活动code
export function getGiftsApi(data) {
  return request({
    method: 'post',
    url: '/Gift/GetGiftsByActivityCode',
    data,
  })
}

// 获取礼品规格
// giftId-礼品Id
export function getGiftAttributesApi(data) {
  return request({
    method: 'post',
    url: '/Gift/GetAttributesByGiftId',
    data,
  })
}


