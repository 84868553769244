<template>
  <div class="mall">
    <div class="mall-content">
      <scroll-load-wrap @scroll-load="scrollLoad">
        <div class="product">
          <div
            v-for="(item, index) in giftList"
            :key="item.id"
            @click="showProductModal(index)"
            class="product-item">
            <div class="product-imgBox">
              <img :src="item.image" class="product-img">
            </div>

            <div class="product-caption">
              <div class="product-main">
                <div class="product-name">{{item.name}}</div>
                <div class="product-stock">
                  库存：<span class="product-quantity">{{item.stock}}</span> 件 
                </div>
              </div>

              <div class="product-bottom">
                <div class="product-points">{{item.price}}</div>
                <div class="product-txt">积分</div>
              </div>

              <div class="product-btn">
                <img src="@/assets/images/icon/arrow-right-white.png" class="product-arrow">
              </div>
            </div>
          </div>
        </div>
      </scroll-load-wrap>
    </div>
  </div>

  <ProductModal 
    v-model:isVisible="isShowProductModal"
    :giftId="currentGiftId" />
</template>

<script src='./Mall.js'></script>

<style scoped lang="scss" src="./Mall.scss"></style>