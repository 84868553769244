<template>
  <div v-if="isShow" class="loading">
    <div class="loading-m">
      <img src="@/assets/images/loading.webp" alt="">
    </div>
  </div>
</template>

<script>
import {
  ref,
} from "vue"

export default {
  name: 'Loading',
  setup() {
    const isShow=ref(false)

    const show = () => {
      isShow.value = true
    }
    const hide = () => {
      isShow.value = false
    }

    return {
      isShow,
      show,
      hide,
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .loading-m {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 240px;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.8);
    img {
      display: block;
      width: 140px;
    }
  }
}

</style>
