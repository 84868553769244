
import { render, createVNode } from "vue"
import ToastComponent from './Toast.vue'

let globalConfig = {
  duration: 3000,
  message: "",
  cb: () => {},//toast结束后的回调
}
let seed = 1

class Toast {
  constructor(options) {
    globalConfig = Object.assign({}, globalConfig, options)
  }
  show(config = {}) {
    let [toastWrapper, toastVM] = [null, null]
    const id = "toast_" + seed++
    toastWrapper = document.createElement("div")
    toastWrapper.id = id
    toastVM = createVNode(
      ToastComponent,
      { ...globalConfig, ...config, id }
    )
    render(toastVM, toastWrapper)
    document.body.appendChild(toastWrapper)
  }
}

Toast.install = (app, options) => {
  let toast = new Toast(options).show
  app.config.globalProperties.$toast = toast
  app.provide("toast", toast)
};

export default Toast

// 使用说明
// 1.在setup中

// setup() {
//   const toast = inject('toast')

//   onMounted(() => {
//     toast({
//       duration: 3000,
//       message: 'setup toast',
//       cb: () => {
//         console.log('setup toast cb');
//       }
//     })
//   })
// },

// 2.在vue2模式中

// this.$toast({
//   duration: 10000,
//   message: data.data || data.error || defaultError,
//   cb: () => {
//     console.log('toast cb');
//   }
// })