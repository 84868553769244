import { request } from '@/utils/request'

/**
 * 收件地址列表
 */
export function getAddressApi() {
  return request({
    method: 'post',
    url: '/Address/GetUserAddress',
  })
}

/**
 * 新建收货地址
 * @param province - 省
 * @param city - 市
 * @param district - 区
 * @param street - 详细地址
 * @param telephone - 电话
 * @param consignee - 收件人
 * @param isDefault - 是否设为默认地址
 */
 export function createAddressApi(data) {
  return request({
    method: 'post',
    url: '/Address/CreateNewAddress',
    data,
  })
}

/**
 * 修改收货地址
 * @param id - 地址Id
 * @param province - 省
 * @param city - 市
 * @param district - 区
 * @param street - 详细地址
 * @param telephone - 电话
 * @param consignee - 收件人
 * @param isDefault - 是否设为默认地址
 */
 export function updateAddressApi(data) {
  return request({
    method: 'post',
    url: '/Address/UpdateAddress',
    data,
  })
}

/**
 * 删除收货地址
 * @param id - 地址Id
 */
 export function deleteAddressApi(data) {
  return request({
    method: 'post',
    url: '/Address/DeleteAddress',
    data,
  })
}
