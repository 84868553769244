import wx from 'weixin-js-sdk'

// 当前元素及其祖先元素是否包含指定class 
export function findClassNameByParents(ele, className){
  if(ele) {
    let classNameIndex=ele.className ? ele.className.indexOf(className) : -1
    // 当前元素包含className
    if(classNameIndex>-1) {
      return true
    }
    else {
      // 如果有父元素，查看父元素是否包含className
      if(ele.parentNode) {
        return findClassNameByParents(ele.parentNode, className)
      }
      else {
        return false
      }
    }
  } 
  else {
    return false
  }
  
}

// 获取url上的参数
export function getQueryParameter(name) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
      results = regex.exec(location.search);
  return results == null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const defaultError='网络繁忙，请稍后重试'

// 全面屏底部增加的高度，68=750/375*34
export function judgeIsFullScreen() {
  return window && navigator.userAgent.indexOf('iPhone') > -1 && window.screen.height >= 812 && window.devicePixelRatio >= 2
}

export function checkPhone(phone){ 
  return (/^1[3456789]\d{9}$/.test(phone))
}

export function redirectRegister() {
  // let baseURL = 'https://bdhtml.aivics.com.cn/bd-h5'
  // if(process.env.NODE_ENV === 'production') {
  //   baseURL = 'https://bdhtml.aivics.com.cn/bd-h5'
  // }
  // location.replace(`${baseURL}/#/auth?returnUrl=${encodeURIComponent(location.href)}`)
  let url = '/pages/register/register'
  let redirectUrl = `/pages/index/index?url=${encodeURIComponent(location.href)}`
  wx.miniProgram.redirectTo({
    url: `${url}?redirectUrl=${encodeURIComponent(redirectUrl)}`
  })

}

// 数字每三位加逗号
export function thousands(num){
  var str = num.toString();
  var reg = str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
  return str.replace(reg,"$1,");
}

export function checkAddressForm({name, phone, areaString, address}) {
  if(!name || name.trim().length === 0) {
    return {
      success: false,
      error: '请填写收货人姓名'
    }
  }
  if(!checkPhone(phone)) {
    return {
      success: false,
      error: '请填写正确的手机号'
    }
  }
  if(!areaString || !areaString.value || areaString.value.trim().length === 0) {
    return {
      success: false,
      error: '请选择地区'
    }
  }
  if(!address) {
    return {
      success: false,
      error: '请填写详细地址'
    }
  } else if(address.trim().length < 5) {
    return {
      success: false,
      error: '请填写详细地址，不少于5个字'
    }
  }

  return {
    success: true
  }
}

export function formatPhone(phone) {
  return phone.replace(/^(.{3})(.*)(.{4})$/, '$1 $2 $3')
}