import { request } from '@/utils/request'
// 购物车相关

/**
 * 添加购物车
 * @param activityCode - 活动code
 * @param giftId - 礼品Id
 * @param count - 数量
 * @param attributeRecord1Id - 规格1 Id
 * @param attributeRecord2Id - 规格2 Id
 */
export function addCartApi(data) {
  return request({
    method: 'post',
    url: '/ShoppingCart/CreateShoppingCart',
    data,
  })
}

/**
 * 获取购物车列表
 * @param activityCode - 活动code
 */
export function getCartListApi(data) {
  return request({
    method: 'post',
    url: '/ShoppingCart/GetGiftReceiveLog',
    data,
  })
}

/**
 * 购物车，删除
 * @param shoppingCarId - 购物车Id
 */
export function deleteCartGiftApi(data) {
  return request({
    method: 'post',
    url: '/ShoppingCart/DeleteShoppingCart',
    data,
  })
}

/**
 * 购物车，批量删除
 * @param [{shoppingCarId: id}] - 购物车Id
 */
 export function batchDeleteCartGiftsApi(data) {
  return request({
    method: 'post',
    url: '/ShoppingCart/DeleteShoppingCarts',
    data,
  })
}

/**
 * 修改购物车
 * @param shoppingCarId - 购物车Id
 * @param count - 数量
 * @param attributeRecord1Id - 规格1 Id
 * @param attributeRecord2Id - 规格2 Id
 */
 export function updateCartApi(data) {
  return request({
    method: 'post',
    url: '/ShoppingCart/UpdateShoppingCart',
    data,
  })
}


