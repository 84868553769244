import Home from '@/views/Home/Home.vue'
import Mall from '@/views/Mall/Mall.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '积分商城'
    },
    children: [
      {
        path: '',
        name: 'Mall',
        component: Mall,
        meta: {
          title: '积分商城'
        },
      },
      {
        path: 'ShoppingCart',
        name: 'ShoppingCart',
        component: () => import('@/views/ShoppingCart/ShoppingCart.vue'),
        meta: {
          title: '积分商城'
        },
      },
      {
        path: 'History',
        name: 'History',
        component: () => import('@/views/History/History.vue'),
        meta: {
          title: '积分商城'
        },
      },
    ],
  },
  {
    path: '/FillAddress',
    name: 'FillAddress',
    component: () => import('@/views/FillAddress/FillAddress.vue'),
    meta: {
      title: '填写地址'
    },
  },
  {
    path: '/EditAddress/:id',
    name: 'EditAddress',
    component: () => import('@/views/EditAddress/EditAddress.vue'),
    meta: {
      title: '编辑地址'
    },
  },
  {
    path: '/AddressList',
    name: 'AddressList',
    component: () => import('@/views/AddressList/AddressList.vue'),
    meta: {
      title: '我的收货地址'
    },
  },
  {
    path: '/SubmitOrder',
    name: 'SubmitOrder',
    component: () => import('@/views/SubmitOrder/SubmitOrder.vue'),
    meta: {
      title: '提交订单'
    },
  },
  {
    path: '/OrderDetail/:bookingId',
    name: 'OrderDetail',
    component: () => import('@/views/OrderDetail/OrderDetail.vue'),
    meta: {
      title: '订单详细'
    },
  },
]

// 重新组织后导出
export default [
  ...routes,
]