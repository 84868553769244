import { createStore } from "vuex"
import createPersistedState from "vuex-persistedstate"
import {
  ADD_KEEPALIVE,
  DELETE_KEEPALIVE,
  SET_TOKEN,
  SET_IS_FULL_SCREEN,
  SET_CODE,
  SET_MY_POINTS,
  SET_ORDER_ADDRESS,
  SET_ORDER_GIFTS,
} from './mutation-types.js'


// 非Module格式：xxxx
// 使用了Module的格式：ModuleName.xxxx
const PERSIST_PATHS = ['token']

export default createStore({
  state: {
    token: '',
    code: '',//最新活动Code
    catchList: [],//需要缓存的组件
    isFullScreen: false,//是否全面屏
    myPoints: 0,//积分
    orderAdress: {},//提交订单时用的地址
    orderGifts: [],//下单的产品
    expressFee: 12,// 运费
    isOpen: true, // 商城是否是开启状态
  },
  mutations: {
    [SET_TOKEN](state, payload) {
      state.token = payload
    },
    [SET_CODE](state, payload) {
      state.code = payload
      if (!state.code) {
        state.isOpen = false
      }
    },
    [SET_MY_POINTS](state, payload) {
      state.myPoints = payload
    },
    [ADD_KEEPALIVE](state, payload) {
      !state.catchList.includes(payload) &&
      state.catchList.push(payload)
    },
    [DELETE_KEEPALIVE](state, payload) {
      state.catchList = state.catchList.filter( x => x !== payload)
    },
    [SET_IS_FULL_SCREEN](state, payload) {
      state.isFullScreen = payload
    },
    [SET_ORDER_ADDRESS](state, payload) {
      state.orderAdress = payload
    },
    [SET_ORDER_GIFTS](state, payload) {
      state.orderGifts = payload
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      key: 'BD-credit',
      paths: PERSIST_PATHS,
    }),
  ],
})


// 数据 e.g
// orderGifts: [{
//   "shoppingCartId": 1,
//   "count": 2,
//   "giftId": 1,
//   "giftName": "IPhone12",
//   "image": "https://bdwechatlog.blob.core.chinacloudapi.cn/bwxd/icon-topic-clinical.png",
//   "price": 10,
//   "attributeRecord1Id": 1,
//   "attributeRecord2Id": 5,
//   "attributeRecord1Value": "红色",
//   "attributeRecord2Value": "32G"
// }]