import { createRouter, createWebHistory } from "vue-router"
// 路由数据
import routes from './routes'
import store from '@/store'
import {
  ADD_KEEPALIVE,
} from '@/store/mutation-types.js'


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach( (to) => {
  // 在路由全局钩子beforeEach中，根据keepAlive属性，统一设置页面的缓存性
  // 作用是每次进入该组件，就将它缓存
  if (to.meta.keepAlive) {
    store.commit(ADD_KEEPALIVE, to.name)
  }

})

router.afterEach( (to) => {
  document.title = to.meta.title || '积分商城'

})

export default router
